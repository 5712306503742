
import React from 'react'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import SecondaryNav from '../components/secondarynav'



import BorderedBox from '../components/borderedbox'
import TitleHeader from '../components/titleheader'
import Card from '../components/card'

// Images
import corporateimage1 from '../assets/images/corporate-responsibility/Corporate_Responsibility_ReponsibleSourcing_03.jpg'
import corporateimage2 from '../assets/images/corporate-responsibility/Corporate_Responsibility_ReponsibleSourcing_07.jpg'
import corporateimage3 from '../assets/images/corporate-responsibility/Corporate_Responsibility_ReponsibleSourcing_11.jpg'
import corporateimage4 from '../assets/images/corporate-responsibility/Corporate_Responsibility_ReponsibleSourcing_14.jpg'


import corporatesourcingimage1 from '../assets/images/corporate-responsibility/betterwork-banner.png'
import corporatesourcingimage2 from '../assets/images/corporate-responsibility/slc-banner.png'
import corporatesourcingimage3 from '../assets/images/corporate-responsibility/nest-banner.png'
import corporatesourcingimage4 from '../assets/images/corporate-responsibility/bsr-banner.jpg'
import corporatesourcingimage5 from '../assets/images/corporate-responsibility/care_logo-big.gif'
import corporatesourcingimage6 from '../assets/images/corporate-responsibility/ccr-csr-logo.png'
import corporatesourcingimage7 from '../assets/images/corporate-responsibility/sustainable-ac-banner.jpeg'
import corporatesourcingimage8 from '../assets/images/corporate-responsibility/BCI-logo-better-cotton-initiative.png'
import corporatesourcingimage9 from '../assets/images/corporate-responsibility/afirm-group-logo-tm_blue.png'
import corporatesourcingimage10 from '../assets/images/corporate-responsibility/nirapon-logo.jpg'



import codeofconduct from '../assets/images/corporate-responsibility/Vendor-Code-Of-Conduct.pdf'
import vendorenvironmentalpolicy from '../assets/images/corporate-responsibility/vendorenvironmentalpolicy.pdf'
import supplychain from '../assets/images/corporate-responsibility/Supply-Chain-Transparency.pdf'

import { trackPageData, getPageDetails } from '../analytics';

class ResponsibleSourcing extends React.PureComponent {
  
  constructor(props) {
    super(props);

    let dataManager = {
        pageDetails: {}
    };
    if(typeof window !== `undefined`) {
      if (window.dataManager) {
          dataManager = window.dataManager;
      }
    }
    dataManager.pageDetails = getPageDetails();
  }
  
  componentDidMount() {
    trackPageData();
  }
  
  render() {
    return (
      <Layout>
    <SEO title="Responsible Sourcing | The Children's Place Corporate Website" description="The Children's Place is committed to responsible sourcing practices in our supply chain. Learn more on The Children's Place Corproate Website." />
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <TitleHeader image={corporateimage1} title="CORPORATE RESPONSIBILITY" addClass="corporateTitle" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <SecondaryNav
          active="Responsible Sourcing"
          name1="Responsible Sourcing" link1="/responsible-sourcing"
          name2="Philanthropic Programs" link2="/philanthropic-programs"
        />
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <BorderedBox>
          <h2>RESPONSIBLE SOURCING AT THE CHILDREN’S PLACE</h2>
          <p>We are committed to responsible sourcing practices in our supply chain. Our guiding principal is to protect and enhance our brand by positively impacting the lives of people who make our products along with the communities and environments affected by our business. To achieve this, our team works with our global vendors on:</p>
          <ul class="list-inline list-bullet text-left">
            <li >Initiatives to create fair and safe factory working conditions&nbsp;&nbsp;&nbsp;&nbsp;</li>
            <li >Educational programs that support workers in their daily lives</li>
            <li >Reducing environmental impacts throughout the supply chain</li>
          </ul>
        </BorderedBox>
      </div>
    </div>


    <div id="sourcinggroup">
      {/* safe working */}
      <div class="row mb-5 text-center">
        <div class="col-md-6 col-sm-6 col-xs-6 fill d-md-none">
          <img src={corporateimage2} title="" className="img-fluid" alt="..." />
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6">
          <Card
            title="FAIR &amp; SAFE FACTORY WORK CONDITIONS"
            description="The Children's Place does not own or operate any manufacturing facilities, but rather, contracts with global vendors to manufacture products in their network of factories. Our goal is to work with those who are committed to providing fair and safe working conditions for their factory workers. We seek to work with global vendors who demonstrate strong business ethics and transparency in their sourcing practices. It is our belief that all factory workers who make our products deserve to have decent and safe work environments."
          >
            <button class="btn btn-primary more-less" type="button" data-toggle="collapse" data-target="#corpMoreone" aria-expanded="false" aria-controls=""></button>
          </Card>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 fill d-none d-md-flex">
          <img src={corporateimage2} title="" className="img-fluid" alt="..." />
        </div>
      </div>


      <div class="row mb-5 collapse" id="corpMoreone" data-parent="#sourcinggroup">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <BorderedBox classname="mb-n-border">
            <button class="btn close-button" type="button" data-toggle="collapse" data-target="#corpMoreone" aria-expanded="false" aria-controls="">X</button>

            <p>To support our commitment to responsible sourcing, we have developed a <a href={codeofconduct}>Vendor Code of Conduct</a>. The Vendor Code of Conduct promotes compliance by our global vendors with applicable laws and supports the principles stated in international standards, such as the International Labor Organization (ILO) core conventions, the UN Guiding Principles on Business and Human Rights and the Organization for Economic Cooperation and Development (OECD) Guidelines for Multinational Enterprises. In line with the 2012 California Transparency in Supply Chains Act, our Vendor Code of Conduct specifically prohibits slavery, human trafficking, forced and involuntary labor. We remain committed to increasing transparency in our supply chain to help prevent human trafficking and forced labor.</p>
            <p><a href={supplychain}>Learn more about our commitment</a>.</p>
            <p>The Children's Place administers a factory audit program to evaluate and monitor factory working conditions against our Vendor Code of Conduct. We require audits by our Responsible Sourcing team (or a third party provider) for all new suppliers producing and/or processing our merchandise as part of the production approval process. We re-audit existing suppliers to verify compliance to our standards and identify areas for improvement. While we recognize that no audit program can guarantee that every factory used by every vendor for The Children's Place is in full compliance with our Vendor Code of Conduct, we continually strive to enhance our audit program. Our Responsible Sourcing team offers guidance and training to vendors and factories to resolve issues identified during audits. We engage with industry experts, such as Better Work, to enhance our processes through consulting services that build strong management systems and worker-management relations. We also include Responsible Sourcing as one of five categories measured in our Vendor Performance Scorecard alongside traditional manufacturing metrics to encourage continuous improvement.</p>
          </BorderedBox>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-5">
          <hr class="d-md-none border-top" />
        </div>
      </div>

      <div class="row mb-5 text-center">
        <div class="col-12"><p><small>Learn more about our responsible sourcing partners by clicking their logos below.</small></p></div>
        <div class="col-sm-3 col-12 pl-5 pr-5">
          <button class="btn logo-btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreSeven" aria-expanded="false" aria-controls="">
            <img src={corporatesourcingimage1} title="" className="img-fluid" alt="..." />
          </button>
        </div>
        <div class="col-sm-3 col-12 pl-5 pr-5">
          <div class="pt-4 d-none d-md-flex"></div>
          <button class="btn logo-btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreEight" aria-expanded="false" aria-controls="">
            <img src={corporatesourcingimage2} title="" className="img-fluid" alt="..." />
          </button>
        </div>


        <div class="col-sm-3 col-12 pl-5 pr-5">
          <button class="btn logo-btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreTen" aria-expanded="false" aria-controls="">
            <img src={corporatesourcingimage10} title="" className="img-fluid" alt="..." />
          </button>
        </div>



        <div class="col-sm-3 col-12 pl-5 pr-5">
          <button class="btn logo-btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreNine" aria-expanded="false" aria-controls="" style={{ maxWidth: 100 }}>
            <img src={corporatesourcingimage3} title="" className="img-fluid" alt="..." />
          </button>
        </div>





      </div>

      <div class="row mb-5 collapse" id="DonateMoreSeven" data-parent="#sourcinggroup">
        <div class="col-md-12 col-sm-12 col-xs-12">
          {/* GOOD+ Foundation */}
          <BorderedBox classname="mb-n-border">
            <button class="btn logo-btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreSeven" aria-expanded="false" aria-controls="">X</button>
            <p>
              Better Work is a global initiative mobilizing brands, governments, factory owners and workers to improve working conditions in the garment industry. The program, a partnership between the UN's ILO and the IFC, a member of the World Bank Group, manages factory assessments and trainings to empower factories to improve the lives of their workers. The Children's Place supports Better Work's priorities of setting up structures to boost worker-management dialog and implement lasting workplace improvements.
          </p><p><a href="https://betterwork.org/global/">Learn more about Better Work.</a>
            </p>
          </BorderedBox>
        </div>
      </div>

      <div class="row mb-5 collapse" id="DonateMoreEight" data-parent="#sourcinggroup">
        {/* IronMatt (The Matthew Larson Foundation)  */}
        <div class="col-md-12 col-sm-12 col-xs-12">
          <BorderedBox classname="mb-n-border">
            <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreEight" aria-expanded="false" aria-controls="">X</button>
            <p>
              The Children's Place is a signatory of the Social &amp; Labor Convergence Project (SLCP). This multi-stakeholder initiative is a collaboration between manufacturers, retailers, industry groups, and civil society organizations to develop a simple, unified and effective assessment framework to factory monitoring. Our shared goal is to increase the effectiveness of factory audits, reduce audit fatigue and better utilize collective resources in the apparel supply chain.
          </p><p><a href="https://slconvergence.org/">Learn more about SLCP.</a>
            </p>
          </BorderedBox>
        </div>
      </div>


      <div class="row mb-5 collapse" id="DonateMoreTen" data-parent="#sourcinggroup">
        {/* Youth Consultation Services (YCS)  */}
        <div class="col-md-12 col-sm-12 col-xs-12">
          <BorderedBox classname="mb-n-border">
            <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreNine" aria-expanded="false" aria-controls="">X</button>
            <p>As a member of Nirapon, The Children’s Place is committed to sustaining a culture of factory safety in Bangladesh. Building on the work of the Alliance for Bangladesh Worker Safety, Nirapon is a locally managed organization tasked with overseeing the ongoing safety, training and helpline efforts of the factories from which Nirapon members source.
          </p><p><a href="https://www.nirapon.org/">Learn more about Nirapon.</a>
            </p>
          </BorderedBox>
        </div>
      </div>


      <div class="row mb-5 collapse" id="DonateMoreNine" data-parent="#sourcinggroup">
        {/* Youth Consultation Services (YCS)  */}
        <div class="col-md-12 col-sm-12 col-xs-12">
          <BorderedBox classname="mb-n-border">
            <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreNine" aria-expanded="false" aria-controls="">X</button>
            <p>Nest is a nonprofit organization focused on developing ethical sourcing practices in the artisan and work-from-home supply chains. The Children's Place is a Nest Steering Committee member and supports a vision to increase economic opportunities for artisans who adopt social responsibility as a part of their business practices.
          </p><p><a href="https://www.buildanest.org/">Learn more about Nest.</a>
            </p>
          </BorderedBox>
        </div>
      </div>
      {/* end safe working */}

      {/* education */}
      <div class="row mb-5 text-center">
        <div class="col-md-6 col-sm-6 col-xs-6">
          <img src={corporateimage3} title="" className="img-fluid" alt="..." />
        </div>

        <div class="col-md-6 col-sm-6 col-xs-6">
          <Card
            title="EDUCATION PROGRAMS TO SUPPORT WORKERS IN THEIR DAILY LIVES"
            description="At The Children’s Place, we recognize that we can play a role in trying to improve the lives of the factory workers who make our products. We believe such programs move our responsible sourcing activities beyond an approach based solely on compliance by focusing on workers’ needs and well-being. These programs support our business goals by working to reduce absenteeism and improve factory productivity."
          >
            <button class="btn btn-primary more-less" type="button" data-toggle="collapse" data-target="#corpMoretwo" aria-expanded="false" aria-controls=""></button>
          </Card>
        </div>
      </div>


      <div class="row mb-5 collapse" id="corpMoretwo" data-parent="#sourcinggroup">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <BorderedBox classname="mb-n-border">
            <button class="btn close-button" type="button" data-toggle="collapse" data-target="#corpMoretwo" aria-expanded="false" aria-controls="">X</button>
            <p>The Children’s Place sponsors factory workplace programs focused on health, nutrition, positive gender relations and parent-child separation. We partner with non-profit organizations that have expertise of running factory education programs in the countries we operate in. Through this approach, The Children’s Place strives to create impact through a partnership with leading organizations whose goal it is to drive positive change in the garment industry.</p>
            <p>The Children’s Place promotes programs such as BSR’s HERproject, CARE’s Healthy Food, Healthy Workplace program and The Center For Child Rights &amp; Corporate Social Responsibility’s (CCR CSR) Parent’s At Work program. Learn more about these organizations.</p>
          </BorderedBox>
        </div>
      </div>
     
     
     
      <div class="row">
        <div class="col-12 mb-5">
          <hr class="d-md-none border-top" />
        </div>
      </div>
      <div class="row mb-5 text-center">
        <div class="col-12"><p><small>Learn more about our responsible sourcing partners by clicking their logos below.</small></p></div>
        <div class="col-sm-4  col-12 pl-5 pr-5">
          <button class="btn logo-btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreOne" aria-expanded="false" aria-controls="">
            <img src={corporatesourcingimage4} title="" className="img-fluid" alt="..." /></button>
        </div>

        <div class="col-sm-4 col-12 pl-5 pr-5">
          <button class="btn logo-btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreTwo" aria-expanded="false" aria-controls=""> <img src={corporatesourcingimage5} title="" className="img-fluid" alt="..." /></button>
        </div>


        <div class="col-sm-4 col-12 pl-5 pr-5">
          <button class="btn logo-btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreThree" aria-expanded="false" aria-controls=""> <img src={corporatesourcingimage6} title="" className="img-fluid" alt="..." /></button>
        </div>
      </div>

      <div class="row mb-5 collapse" id="DonateMoreOne" data-parent="#sourcinggroup">
        <div class="col-md-12 col-sm-12 col-xs-12">
          {/* GOOD+ Foundation */}
          <BorderedBox classname="mb-n-border">
            <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreOne" aria-expanded="false" aria-controls="">X</button>
            <p>
              BSR's HERproject aims to unlock the full potential of garment workers through workplace programs promoting health, financial inclusion and gender equality. Through HERproject, The Children’s Place partners with its global vendors and local NGOs to help drive positive impact for women and for its business.
          </p><p><a href="https://herproject.org/">Learn more about HERproject.</a>
            </p>
          </BorderedBox>
        </div>
      </div>

      <div class="row mb-5 collapse" id="DonateMoreTwo" data-parent="#sourcinggroup">
        {/* IronMatt (The Matthew Larson Foundation)  */}
        <div class="col-md-12 col-sm-12 col-xs-12">
          <BorderedBox classname="mb-n-border">
            <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreTwo" aria-expanded="false" aria-controls="">X</button>
            <p>
              CARE’s Healthy Food, Healthy Workplace project aims to improve knowledge of nutritious foods and hygiene to improve worker health. Managed by CARE International, a global poverty-fighting organization, the program also engages with factories to improve food options available to garment factory workers. The Children's Place has invested in this program in Cambodia to support healthy eating choices and combat instances of workplace fainting in the garment sector.
          </p><p><a href="http://www.care-cambodia.org/tcp">Learn more about the Healthy Food, Healthy Workplace project.</a>
            </p>
          </BorderedBox>
        </div>
      </div>


      <div class="row mb-5 collapse" id="DonateMoreThree" data-parent="#sourcinggroup">
        {/* Youth Consultation Services (YCS)  */}
        <div class="col-md-12 col-sm-12 col-xs-12">
          <BorderedBox classname="mb-n-border">
            <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreThree" aria-expanded="false" aria-controls="">X</button>
            <p>The Centre for Child Rights and Business (shortened as 'The Centre’ and formerly known as CCR CSR) supports businesses to deliver improvements within their supply chains that not only benefit workers, families and children, but also deliver positive business outcomes. The Centre offers global expertise, services and support covering a broad range of child rights and worker well-being issues. The Children’s Place partners with The Centre on programs focused on parent training and childcare with our Vendor Factories.
          </p><p><a href="http://www.ycs.org/">Learn more about The Centre.</a>
            </p>
          </BorderedBox>
        </div>
      </div>
      {/* end education */}

      {/* Environmental */}
      <div class="row mb-5 text-center">
        <div class="col-md-6 col-sm-6 col-xs-6 d-md-none">
          <img src={corporateimage4} title="" className="img-fluid" alt="..." />
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6">
          <Card
            title="REDUCED ENVIRONMENTAL IMPACTS THROUGHOUT THE SUPPLY CHAIN"
            description="In 2018, The Children’s Place expanded its responsible sourcing activities again to include environmental stewardship. We join with companies in the apparel and footwear industry, and other non-profit organizations, to work with 3rd party vendors and factories to reduce environmental impacts from manufacturing activities.  We are committed to a long-term, sustainable approach with manufactures that demonstrate leadership in sustainability by using resources responsibly and efficiently."
          >
            <button class="btn more-less" type="button" data-toggle="collapse" data-target="#corpMorethree" aria-expanded="false" aria-controls=""></button>
          </Card>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 d-none d-md-flex">
          <img src={corporateimage4} title="" className="img-fluid" alt="..." />
        </div>
      </div>


      <div class="row mb-5 collapse" id="corpMorethree" data-parent="#sourcinggroup">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <BorderedBox classname="mb-n-border">
            <button class="btn close-button" type="button" data-toggle="collapse" data-target="#corpMorethree" aria-expanded="false" aria-controls="">X</button>
            <p>To support our commitment to environmental sustainability, we have developed a <a href={vendorenvironmentalpolicy}>Vendor Environmental Policy</a>. The policy outlines our expectation that vendors commit to the reduction of environmental impacts from their manufacturing processes. The Vendor Environmental Policy focuses on environmental issues that are more material to our business based on the Sustainability Accounting Standards Board’s (SASB) guidelines for apparel and footwear. These include managing risks related to chemicals, wastewater discharge and raw material sourcing. Supporting more responsible chemical management and wastewater practices benefits both the environment in the regions we source from and the people and businesses who make our product. Because cotton is the primary raw material used in our product, promoting the use of responsibility-sourced cotton can alleviate issues in its cultivation through better management of water, pesticide use and workplace standards for farmers. To achieve our goals, The Children’s Place may require manufacturers to undergo third party environmental assessments to verify they meet local laws and regulations.</p>
            <p>The Children’s Place has joined collaborations such as the Sustainable Apparel Coalition (SAC), The Afirm Group and the Better Cotton Initiative (BCI) to partner with like-minded organizations that strive to improve the environmental impact of the industry. Learn more about these organizations and their programs below.</p>
          </BorderedBox>
        </div>
      </div>

      
      <div class="row">
        <div class="col-12 mb-5">
          <hr class="d-md-none border-top" />
        </div>
      </div>
      <div class="row mb-5 text-center">
        <div class="col-12"><p><small>Learn more about our responsible sourcing partners by clicking their logos below.</small></p></div>
        <div class="col-sm-4 col-12 pl-5 pr-5">
          <button class="btn logo-btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreFour" aria-expanded="false" aria-controls=""> <img src={corporatesourcingimage7} title="" className="img-fluid" alt="..." /></button>
        </div>

        <div class="col-sm-4 col-12 pl-5 pr-5">
          <button class="btn logo-btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreFive" aria-expanded="false" aria-controls=""> <img src={corporatesourcingimage8} title="" className="img-fluid" alt="..." /></button>
        </div>


        <div class="col-sm-4 col-12  ">
          <button class="btn logo-btn close-button text-center" type="button" data-toggle="collapse" data-target="#DonateMoreSix" aria-expanded="false" aria-controls=""> <img src={corporatesourcingimage9} title="" className="img-fluid" alt="..." /></button>
        </div>

      </div>

      <div class="row mb-5 collapse" id="DonateMoreFour" data-parent="#sourcinggroup">
        <div class="col-md-12 col-sm-12 col-xs-12">
          {/* GOOD+ Foundation */}
          <BorderedBox classname="mb-n-border">
            <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreFour" aria-expanded="false" aria-controls="">X</button>
            <p>
              The Sustainable Apparel Coalition (SAC) is an industry-wide group of more than 230 members working to reduce the environmental and social impacts of products around the world. Through multi-stakeholder engagement, the SAC seeks to lead the industry toward a shared vision of sustainability built upon a common approach for measuring and evaluating apparel, footwear, and textile product sustainability performance that spotlights priorities for action and opportunities for technological innovation.
          </p><p><a href="https://apparelcoalition.org/">Learn more about the Sustainable Apparel Coalition.</a>
            </p>
          </BorderedBox>
        </div>
      </div>

      <div class="row mb-5 collapse" id="DonateMoreFive" data-parent="#sourcinggroup">
        {/* IronMatt (The Matthew Larson Foundation)  */}
        <div class="col-md-12 col-sm-12 col-xs-12">
          <BorderedBox classname="mb-n-border">
            <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreFive" aria-expanded="false" aria-controls="">X</button>
            <p>


            The Better Cotton Initiative (BCI) is the largest cotton sustainability program in the world. Working with organizations from farming, retail fashion and textile brands, BCI is an agricultural standard system helping farmers use less water and fewer chemical pesticides and fertilizers, all while improving yields, profitability and worker’s lives. Better Cotton is sourced via a system of Mass Balance. We are committed to sourcing 75% of our cotton as ‘more sustainable cotton’ by the end of fiscal 2025. ‘More sustainable cotton’ includes Better Cotton, recycled cotton and organic cotton.

          </p><p><a href="https://bettercotton.org/">Learn more about the Better Cotton Initiative.</a>
            </p>
          </BorderedBox>
        </div>
      </div>


      <div class="row mb-5 collapse" id="DonateMoreSix" data-parent="#sourcinggroup">
        {/* Youth Consultation Services (YCS)  */}
        <div class="col-md-12 col-sm-12 col-xs-12">
          <BorderedBox classname="mb-n-border">
            <button class="btn close-button" type="button" data-toggle="collapse" data-target="#DonateMoreSix" aria-expanded="false" aria-controls="">X</button>
            <p>The AFIRM Group works to build safer and more sustainable chemistry within the apparel and footwear supply chains. With members including some of the largest brands and retailers in the world, AFIRM continues to be a recognized global center of excellence, providing resources to enable continuous advancement of chemical management best practices.
          </p><p><a href="https://www.afirm-group.com">Learn more about The AFIRM Group.</a>
            </p>
          </BorderedBox>
        </div>
      </div>
    </div>
    {/* end environmental */}
  </Layout>
    )
  }
}

export default ResponsibleSourcing
